<template>
  <sdk-input placeholder="9876 5432 1098 7654" @blur="onBlur" @focus="onFocus" @input="onInput" />
</template>
<script>
import SdkInput from '@/views/sdk/components/sdk-input.vue'
import { onCardInput, rules } from '@/utils/tools/checkout'
import { sendMessageToClient } from '@/views/sdk/tools'

export default {
  name: 'card-number',
  components: { SdkInput },
  data() {
    return {
      inputValue: '',
      options: {}
    }
  },
  created() {
    window.addEventListener('message', this.listeningMessage)
  },
  methods: {
    listeningMessage(messageData) {
      const { event, data } = messageData.data || {}
      switch (event) {
        case 'moozumi_checkout__settings': {
          this.options = JSON.parse(data)
          break
        }
      }
    },
    sendErrorMessage(value) {
      rules.cardNumber(null, value, (error, type) => {
        if (error) {
          sendMessageToClient('moozumi_checkout__card_verify', { type })
        } else {
          sendMessageToClient('moozumi_checkout__card_verify', { type: '15010' })
        }
      })
    },
    onInput(event) {
      onCardInput(event, (value, card) => {
        event.target.value = value
        this.inputValue = value
        if (this.options.errorTrigger === 'change') {
          this.sendErrorMessage(value)
        }
        sendMessageToClient('moozumi_checkout__card_type', card)
      })
      sendMessageToClient('moozumi_checkout__style_event_change', { type: 'card_number_change' })
    },
    onFocus() {
      sendMessageToClient('moozumi_checkout__style_event_change', { type: 'card_number_focus' })
    },
    onBlur() {
      sendMessageToClient('moozumi_checkout__style_event_change', { type: 'card_number_blur' })
      if (this.options.errorTrigger === 'blur') {
        this.sendErrorMessage(this.inputValue)
      }
    }
  },
  watch: {
    '$store.getters.app.currentLan': 'onLanguageChange'
  }
}
</script>
<style lang="less">
@import '~@/styles/common.less';
</style>
